<template>
  <section class="index-tokens">
    <div class="index-tokens__container container">
      <div class="index-tokens__info">
        <h2 class="index-tokens__title" v-t="'index.tokens.title'" />
        <div class="index-tokens__websites">
          <template v-for="(item, i) in roobeeTokenWebsites">
            <span class="hr" :key="i" v-if="i !== 0">|</span>
            <a target="_blank" :href="item.url" class="index-tokens__websites-item" :key="item.title">
              {{ $t(`index.tokens.markets.${item.title}`) }}
            </a>
          </template>
        </div>
        <div class="index-tokens__cards">
          <div class="index-tokens__cards-row" v-for="(cards, k) in rowCards" :key="k">
            <a target="_blank" v-for="(card, i) in cards" :class="{[card.slug]: true}" :key="i" :href="card.url" class="index-tokens__cards-item">
              <img :src="`/images/tokens/${card.slug}${card.isSvg ? '.svg' : '.png'}`" :alt="card.title">
            </a>
          </div>
        </div>
        <a target="_blank" href="https://coinmarketcap.com/currencies/roobee/" class="index-tokens__more">
          <span v-t="'index.tokens.more-markets'" />
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.262 8L5.181 3.238a.75.75 0 111.138-.976l4.5 5.25a.75.75 0 010 .976l-4.5 5.25a.75.75 0 11-1.138-.976L9.262 8z" fill="#010101" stroke="#FCFCFC" stroke-width=".1"/></svg>
        </a>
      </div>
      <div class="index-tokens__graph">
        <img src="/images/tokens/graph.png" alt="Roobee graph">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Tokens",

  props: ['roobeeTokenWebsites', 'roobeeTokenCards'],

  data() {
    return {
      cardsPerRow: 3
    }
  },

  computed: {
    rowCards() {
      if (this.roobeeTokenCards) {
        let rowCards = [];

        let i, j;
        for (i = 0, j = this.roobeeTokenCards.length; i < j; i += this.cardsPerRow) {
          rowCards.push(this.roobeeTokenCards.slice(i, i + this.cardsPerRow));
        }

        return rowCards;
      }
      return []
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/elements/index/tokens.scss';
</style>
